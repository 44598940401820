import { useLocalStorage } from "usehooks-ts";

import { CHAT_ID_SETTINGS_KEY } from "#app/constants";

export function useChatSettings(chatId?: string) {
	const [settings, setSettings] = useLocalStorage(
		CHAT_ID_SETTINGS_KEY.replace("id", chatId || "default"),
		{
			isImmersiveChatBubblesOn: false,
			isImmersiveModeOn: false,
			isChatSidebarVisible: true,
		},
		{
			initializeWithValue: false,
		},
	);

	return [settings, setSettings] as const;
}
